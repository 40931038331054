import { render, staticRenderFns } from "./countComponent.vue?vue&type=template&id=2d45ddf0&scoped=true&"
import script from "./countComponent.vue?vue&type=script&lang=js&"
export * from "./countComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d45ddf0",
  null
  
)

export default component.exports