<template>
  <v-app>
    <v-system-bar
        dark
        height="40"
        color="#80b62a"
        class="justify-center text-center"
    >
      <v-btn-toggle
          v-model="$i18n.locale"
          background-color="transparent"
          borderless
          mandatory
          dense


      >
        <v-btn value="la" small text plain>
          ພາສາລາວ
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn small value="en" text plain>
          English
        </v-btn>


      </v-btn-toggle>
<!--      <v-btn x-small text>Lao</v-btn>-->
<!--      <v-divider vertical class="ml-1 mr-1"></v-divider>-->
<!--      <v-btn x-small text>English</v-btn>-->

    </v-system-bar>
    <v-sheet color="#02454d" dark min-height="250">

      <v-row class="text-center justify-center ma-1">
        <v-col cols="12" md="7">
          <h1 class="heading ">5.5KM Relay for Community</h1>
          <p class="title">20 August 2023</p>
          <p class="subtitle-1">Registration: Regular – Now – 15 August 2023
          </p>
          <count-component></count-component>
          <v-btn color="#80b62a" v-if="$route.path != '/register'" to="/register" dark width="150">{{$t('register')}}</v-btn>
          <v-btn color="#80b62a" v-if="$route.path != '/'" to="/" dark width="150">{{$t('event_detail')}}</v-btn>
        </v-col>
        <v-col cols="12" md="5">
          <v-card class="mr-2">
            <v-img :src="require('../src/assets/banner.jpeg')" eager   contain></v-img>
          </v-card>

        </v-col>

      </v-row>
    </v-sheet>

    <v-main style="background-color: #f6e9d6">
      <router-view/>
    </v-main>
    <v-footer padless dark>
      <v-col
          class="text-center"
          cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Relay for Community</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>


import CountComponent from "@/components/countComponent";

export default {
  name: 'App',
  components: {CountComponent},
  data: () => ({
    //
  }),
};
</script>
