<template>
  <div>
    <p class="red--text">Time left: {{ days }} days, {{ hours }} hours, {{ minutes }} minutes, {{ seconds }} seconds</p>
  </div>
</template>

<script>
export default {
  name: "countComponent",
  data() {
    return {
      countdownDate: new Date('2023-08-15'), // set your desired countdown date here
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      countdownInterval: null
    }
  },
  mounted() {
    this.countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = this.countdownDate.getTime() - now;
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  }

}
</script>

<style scoped>

</style>