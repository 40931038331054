<template>
  <div class="home mx-2 my-2">

    <v-row >

      <v-col cols="12" md="6">
        <v-card flat  color="#f6e9d6">
          <v-img  :src="require('../assets/event_photo.jpeg')" >
<!--            <v-overlay absolute align-center opacity="0.2" >-->
<!--              <span class="teal darken-4 display-2"   >5.5KM Relay for Community</span>-->
<!--            </v-overlay>-->
          </v-img>

        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="py-8" >

        <v-row class="ml-2 justify-center" >
          <v-col cols="12" md="4" class="text-center  pb-8">
            <h1><u>{{$t('date')}}</u></h1>
            <br>
            <v-icon size="30">mdi-calendar-today</v-icon>{{$t('date_value')}}  <br>
            {{$t('sunday')}}
          </v-col>
          <v-divider vertical ></v-divider>
          <v-col cols="12" md="4" class="text-center pb-8">
            <h1><u>{{$t('flag_off')}}</u></h1>
            <br>
            <v-icon size="30">mdi-clock-time-eight-outline</v-icon> 6:00 AM <br>(Asia/Bangkok time)
          </v-col>
        </v-row>
        <v-divider class="py-2" v-if="$vuetify.breakpoint.mdAndUp"></v-divider>

        <v-row class="ml-2 justify-center" >
          <v-col cols="12" md="4" class="text-center">
            <h1><u>{{$t('location')}}</u></h1>
            <br>
            <v-icon size="30">mdi-map-marker-radius-outline</v-icon> {{$t('thatlaung_lake')}}
          </v-col>
          <v-divider vertical></v-divider>

          <v-col cols="12" md="4" class="text-center">
            <h1><u>{{$t('distance')}}</u></h1>
            <br>
            <v-icon size="30">mdi-map-marker-distance</v-icon> 5.5 {{$t('kilometer')}}
          </v-col>
        </v-row>
      </v-col>
    </v-row>



  </div>
</template>

<script>

export default {
  name: 'HomeView',

}
</script>
<style scoped>

</style>
